import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from "@angular/core";
import { ModalService } from "../modal/modal.service";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-task-inactive")
@Component({
    selector: "s25-ng-task-inactive",
    template: `
        <div
            *ngIf="init"
            class="ngBold ngInlineBlock ngAnchor"
            (click)="click()"
            (keydown.enter)="click()"
            tabindex="0"
        >
            Pending
        </div>
    `,
})
export class S25TaskInactiveComponent implements OnInit {
    @Input() task?: any;

    hasModal: boolean;

    init: boolean = false;
    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        this.init = true;
    }

    click() {
        ModalService.modal("inactive-task", this.task);
    }
}
