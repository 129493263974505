//author travis
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { TaskEditService } from "../../services/task/task.edit.service";
import { S25ItemI } from "../../pojo/S25ItemI";
import { Task } from "../../pojo/Task";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { SearchCriteriaDataUtil } from "../s25-multiselect/search.criteria.data.util";
import { SearchCriteriaContext } from "../../services/search/search-criteria/search.criteria.context";
import { UserprefService } from "../../services/userpref.service";
import { FlsService } from "../../services/fls.service";
import { TaskService } from "../../services/task/task.service";
import { ContactService } from "../../services/contact.service";
import { PopoverComponent } from "../s25-popover/popover.component";
import { SearchCriteriaService } from "../../services/search/search-criteria/search.criteria.service";
import { TaskNormalizeUtil } from "../../services/task/task.normalize.util";

@TypeManagerDecorator("s25-ng-task-contacts-picker")
@Component({
    selector: "s25-ng-task-contacts-picker",
    template: `<div *ngIf="canEdit" style="position: relative">
        <s25-popover
            *ngIf="init"
            [modelBean]="modelBean"
            [openTrigger]="'click'"
            [closeTrigger]="'click'"
            [onBody]="true"
            class="s25-multiselect-popup-placeholder"
        >
            <!--             [placement]="'left-start left left-end bottom-start'"-->
            <!--             "top", "top-left", "top-right", "bottom", "bottom-left", "bottom-right", "left", "left-top", "left-bottom", "right", "right-top", "right-bottom"-->
            <button class="aw-button aw-button--outline">Add Assignees</button>
        </s25-popover>
        <ng-template #popupLoader>
            <div *ngIf="popupInit" class="eventTasksAddAssignees">
                <h3>Add Assignees By:</h3>
                <s25-toggle-button
                    [trueLabel]="'Contact'"
                    [falseLabel]="'Security Group'"
                    [toggleLabel]="'Search By'"
                    [description]="'Add Assignees By:'"
                    [(modelValue)]="isContactMode"
                    (modelValueChange)="changeSelectType($event)"
                ></s25-toggle-button>

                <s25-ng-multiselect *ngIf="isContactMode && multiSelectLoaded" [modelBean]="this.contactsModel">
                </s25-ng-multiselect>

                <s25-ng-multiselect *ngIf="!isContactMode && multiSelectLoaded" [modelBean]="this.groupsModel">
                </s25-ng-multiselect>
                <s25-ng-loading-inline-static *ngIf="isSaving"></s25-ng-loading-inline-static>
            </div>
        </ng-template>
    </div>`,
    // styles: ["h3{margin-top: 2em}", ".toggle{margin-top: 2em;}",
    //     ".eventTasksAddAssignees.aw-button:not(.aw-button--primary) {width: 11em;height: 2.75em;margin: 1em;}",
    //     ".backgroundWhite{background-color: #fff;}",
    // ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

/*
 * Given an array of tasks,
 * Allow user to select some contacts to add
 * Do a PUT to contacts
 * - detect which succeeded and which failed
 * - if the task passed in includes a list of
 */
export class S25TaskContactsPickerComponent implements OnInit {
    @Input() tasks?: any[]; //eventually force to Task.Object{} - as is, there are many formats for tasks, easier to normalize in the component -tw
    @Input() eventId?: number; //Required for BPE emails to be sent
    @Input() additionType: "contacts" | "securityGroups" = "contacts";
    @Input() isOnBody = false;
    @Input() isBulkEdit = false;
    @Input() afterSave?: Function; //to support use with angJS
    @Output() contactsAdded = new EventEmitter<any>();

    @ViewChild("popupLoader", { static: false }) popupLoader: TemplateRef<any>;
    @ViewChild(PopoverComponent) popoverChild: PopoverComponent;

    normalizedTasks: Task.Object[];
    isContactMode: boolean = false;
    canEdit = false;
    init = false;
    popupInit = false;
    multiSelectLoaded = false;

    taskIds: number[];
    contactsModel: MultiselectModelI;
    groupsModel: MultiselectModelI;
    modelBean: any;
    selectedItems: S25ItemI[] = [];

    isSaving = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    closePopup = () => {
        this.popupInit = false;
        this.popoverChild.forceClose();
    };

    cleanupPopup = () => {
        this.isContactMode = true;
        this.contactsModel.addedItems = [];
        this.groupsModel.addedItems = [];
        this.selectedItems = [];
    };

    changeSelectType(newValue: boolean) {
        this.setAdditionType();
        this.selectedItems = [];
        this.multiSelectLoaded = false;
        this.cd.detectChanges();

        this.initMultiSelectModel().then(() => {
            this.cd.detectChanges();
        });
    }

    ngOnInit() {
        this.zone.run(() => {
            return S25Util.all({
                currentGroupId: UserprefService.getGroupId(),
                currentContactId: UserprefService.getContactId(),
                currentUserName: ContactService.getCurrentUsername(),
                fls: FlsService.getFls(),
            }).then((resp) => {
                this.tasks = S25Util.array.forceArray(this.tasks);
                this.normalizedTasks = this.tasks.map((task) => TaskNormalizeUtil.normalizeTaskData(task));
                if (!this.isBulkEdit) {
                    /*
                    To add a contact user must be
                    -1 Admin
                    OR (
                        TASK_LIST = F and VIEW_OTHERS = F
                        AND be a current assignee
                        AND Task is in progress
                        AND Task is not a to-do (different process for todos)
                    )
                     */
                    this.canEdit =
                        this.normalizedTasks.length > 0 &&
                        (resp.currentGroupId === -1 || (resp.fls.TASK_LIST === "F" && resp.fls.VIEW_OTHERS === "F")) &&
                        (resp.currentGroupId === -1 ||
                            S25Util.array.isIn(
                                this.normalizedTasks[0].contacts,
                                "contId",
                                parseInt(resp.currentContactId),
                            )) &&
                        this.normalizedTasks.some(
                            (task) =>
                                [Task.States.InProgress, Task.States.Various].includes(task.overallState) &&
                                task.taskType !== Task.WorkflowTypes.todo,
                        );

                    //Can't edit the task don't do anything else
                    if (!this.canEdit) return;
                    this.cd.detectChanges();

                    this.eventId = this.eventId || this.normalizedTasks[0].eventId;
                    this.taskIds = this.normalizedTasks.map((el) => el.taskId);
                } else {
                    this.canEdit = true;
                    this.cd.detectChanges();
                }

                this.isContactMode = "securityGroups" != this.additionType; //default to contactMode
                this.modelBean = {
                    popoverClass: "s25-multiselect-popup",
                    popoverTemplate: this.popupLoader,
                    title: "Assignees",
                    onShow: this.initMultiSelectModel,
                    onHide: this.cleanupPopup,
                };

                this.init = true;
                this.cd.detectChanges();
                // return this.initMultiSelectModel().then(()=> {
                //     this.init = true;
                //     this.cd.detectChanges();
                // });
            });
        });
    }

    initMultiSelectModel = () => {
        this.groupsModel = {
            showResult: false,
            showMatching: true,
            filterFavorites: false,
            singleSelect: false,
            hasCancel: false,
            hasDone: true,
            hasCommit: true,
            onCommit: this.saveGroups,
            isInline: true,
            items: [],
            uncommittedSelectedItems: [],
        };

        this.contactsModel = {
            showResult: false,
            showMatching: true,
            filterFavorites: false,
            singleSelect: false,
            hasCancel: false,
            hasDone: true,
            hasCommit: true,
            onCommit: this.saveContacts,
            isInline: true,
            uncommittedSelectedItems: [],
        };

        this.setAdditionType();
        return SearchCriteriaDataUtil.searchCriteriaSetup(
            this.isContactMode ? this.contactsModel : this.groupsModel,
            SearchCriteriaContext.Context[this.additionType],
            this.selectedItems,
            this.elementRef.nativeElement,
            null,
            this.isContactMode ? "&is_r25user=1" : null,
        ).then(() => {
            if (this.isContactMode) {
                this.popupInit = true;
                this.multiSelectLoaded = true;
            } else {
                //Having trouble getting security group searching to work so directly assigning security group. - T
                return SearchCriteriaService.getSecurityGroups().then((resp) => {
                    this.groupsModel.items = resp;
                    this.popupInit = true;
                    this.multiSelectLoaded = true;
                });
            }
        });
    };

    saveGroups = () => {
        if (!this.isBulkEdit) {
            this.isSaving = true;
            this.cd.detectChanges();
            return TaskEditService.microUpdate(
                this.normalizedTasks,
                { assignGroup: this.groupsModel.addedItems },
                this.eventId,
            ).then(
                (resp) => {
                    this.isSaving = false;
                    this.cd.detectChanges();
                    this.closePopup();
                    this.afterSave && this.afterSave();

                    return this.findNewlyAddedContactsByGroup(resp).then((newContacts) => {
                        this.contactsAdded.emit({ items: newContacts, type: "Security Group" });
                    });
                },
                (e) => {
                    this.isSaving = false;
                    this.cd.detectChanges();
                },
            );
        } else {
            this.contactsAdded.emit({ items: this.groupsModel.addedItems, type: "Security Group" });
            this.closePopup();
            return;
        }
    };

    /*
    Use the task micro service to save the new contacts and determine which contacts are newly added
     */
    saveContacts = () => {
        if (!this.isBulkEdit) {
            this.isSaving = true;
            this.cd.detectChanges();
            return TaskEditService.microUpdate(
                this.normalizedTasks,
                { assignContact: this.contactsModel.addedItems },
                this.eventId,
            ).then(
                (resp) => {
                    // const finalContacts = resp?.resp?.data[0].contacts;
                    const addedContacts = this.findNewlyAddedContacts(resp?.resp, this.contactsModel?.addedItems);

                    this.contactsAdded.emit({
                        items: addedContacts,
                        type: "Contact",
                    });
                    this.contactsModel.addedItems = [];

                    this.isSaving = false;
                    this.cd.detectChanges();
                    this.closePopup();
                    this.afterSave && this.afterSave();
                    return this.selectedItems;
                },
                (e) => {
                    this.isSaving = true;
                    this.cd.detectChanges();
                },
            );
        } else {
            this.contactsAdded.emit({ items: this.contactsModel.addedItems, type: "Contact" });
            this.afterSave && this.afterSave();
            this.closePopup();
            return;
        }
    };

    async findNewlyAddedContactsByGroup(resp: any): Promise<S25ItemI[]> {
        const addedItems = await ContactService.getContactsByGroup(
            this.groupsModel?.addedItems?.map((item) => {
                return Number(item.itemId);
            }),
        );

        return this.findNewlyAddedContacts(resp, addedItems);
    }
    findNewlyAddedContacts(resp: any, addedCandidates: S25ItemI[]): S25ItemI[] {
        let addedContacts: any[] = [];
        this.normalizedTasks.forEach((task) => {
            const finalContacts = S25Util.array.getByProp(resp?.data, "taskId", task.taskId);
            addedCandidates.forEach((cont) => {
                const details = S25Util.array.getByProp(finalContacts.contacts, "contId", cont.itemId);
                if (!S25Util.array.isIn(task.contacts, "contId", cont.itemId) && !!details) {
                    cont.notifyType = details.notifyType;
                    addedContacts.push(cont);
                }
            });
        });

        return addedContacts;
    }

    //ugg Tasks live in many formats, try normalizing here
    getState(task: any) {
        return S25Util.parseInt(
            task.overallState || task.approval_state || task.task_state || task.taskAction?.itemStateId,
        );
    }

    setAdditionType() {
        this.additionType = !!this.isContactMode ? "contacts" : "securityGroups";
    }
}
