import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25ItemI } from "../../pojo/S25ItemI";
import { Event } from "../../pojo/Event";
import { Task } from "../../pojo/Task";

@TypeManagerDecorator("s25-ng-task-assign")
@Component({
    selector: "s25-ng-task-assign",
    template: `
        <div *ngIf="init">
            <s25-ng-task-contacts-picker
                *ngIf="!isTodo"
                [tasks]="taskItem"
                (contactsAdded)="contactsAdded($event)"
                [eventId]="taskItem.eventId"
                [isOnBody]="true"
                [afterSave]=""
            ></s25-ng-task-contacts-picker>

            <div class="ngCpointer" (click)="toggleCollapse()">{{ collapse ? "&#x25BC;" : "&#x25B2;" }}</div>

            <div *ngIf="!collapse">
                <div *ngFor="let status of approvalState">
                    <div class="ngBold">{{ status[0] }}</div>
                    <!--notify tasks-->
                    <div *ngIf="taskType === 1 || taskType === 2">
                        <span *ngFor="let approver of status[1]">{{ approver }}</span>
                    </div>
                    <!--ap tasks-->
                    <div *ngIf="taskType === 3 || taskType === 4" class="c-assignedToCol-flexWrapper">
                        <span *ngFor="let contact of approval.approval_contact" class="c-approvalContact">
                            {{ contact.approval_contact_name }}
                        </span>
                    </div>
                </div>
                <div *ngIf="showAllApproveMsg" class="c-margin-top--single">
                    All contacts must approve Authorizations.
                </div>
            </div>

            <div class="c-margin-left--single">
                <s25-ng-table-list [items]="items" *ngIf="collapse"></s25-ng-table-list>
            </div>
        </div>
    `,
    styles: `
        .c-assignedToCol-flexWrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .c-approvalContact {
                padding: 3px 20px 0 0;
            }
        }
    `,
})
export class S25TaskAssignComponent implements OnInit {
    @Input() items?: S25ItemI[];
    @Input() taskItem?: any;
    @Input() textValue?: string;
    @Input() approval?: Event.Workflow.Task;
    @Input() taskType?: Task.Id;

    init: boolean = false;
    isTodo: boolean = false;
    approvalState: any[];
    collapse: boolean = true;
    showAllApproveMsg: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        this.taskItem.taskId = this.taskItem.itemId;
        this.taskItem.state = this.taskItem.itemStateId;
        this.taskItem.overallState = this.taskItem.itemStateId;
        this.taskItem.isTodo ? (this.isTodo = true) : (this.isTodo = false);
        this.getApprovalStatuses();
        this.init = true;
    }

    contactsAdded(contacts: { type: string; items: S25ItemI[] }) {
        if (!contacts) return;
        contacts.items = S25Util.array.forceArray(contacts.items);
        this.items = this.items.concat(
            contacts.items.map((cont) => {
                return { text: cont.itemName };
            }),
        );
        this.cd.detectChanges();
    }

    /**
     * adds them to this.approvalState with their task status authorized, in progress etc.
     */
    getApprovalStatuses = () => {
        const messages: { [key: string]: any[] } = {};
        if (this.approval?.approval_contact && this.approval?.approval_contact?.length > 0) {
            for (let contact of this.approval?.approval_contact) {
                const status = `${contact?.notification_type_name} ${contact?.approval_contact_state_name
                    .split("/")
                    .join("/ ")}`;
                messages[status] ??= [];
                messages[status].push(contact?.approval_contact_name);
                if (this.approval?.notify_type_id === 2) {
                    if (contact?.notification_type_id === 2 && !this.showAllApproveMsg) this.showAllApproveMsg = true;
                    this.showAllApproveMsg = !!this.showAllApproveMsg;
                } else this.showAllApproveMsg = false;
            }
            this.approvalState = Object.entries(messages)
                .map(([type, arr]) => [type, arr.sort()])
                .sort();
        }
    };

    toggleCollapse() {
        this.collapse = !this.collapse;
    }
}
