import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Task } from "../../pojo/Task";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { AccessLevels } from "../../pojo/Fls";
import { FlsService } from "../../services/fls.service";
import { UserprefService } from "../../services/userpref.service";
import { TaskChange, TaskService } from "../../services/task/task.service";
import { TaskTiersService } from "../../services/task/task.tiers.service";
import { TaskActionCellFactory } from "../../services/task/task.list.service";

@TypeManagerDecorator("s25-ng-task-action")
@Component({
    selector: "s25-ng-task-action",
    template: `
        <s25-ng-loading-inline-static *ngIf="isLoading" [text]="''"></s25-ng-loading-inline-static>
        <div *ngIf="!isLoading">
            <span
                class="ngItalic ngTaskActionText"
                *ngIf="(!hasApproveDenyPerms && !hasCancelPerms) || (taskBlocked && workflowChained)"
                >{{ itemText || "None" }}</span
            >

            <div class="ngTaskActionButtons c-modal-actions-flex" *ngIf="hasApprove">
                <div
                    *ngIf="!showConfirm"
                    class="taskStateChoice taskStatePositive ngCpointer ngNoSelect ngInlineBlock"
                    (click)="approve()"
                    (keydown.enter)="approve()"
                    tabindex="0"
                >
                    <div>
                        <div class="sprite_approve ngInlineBlock">
                            <svg class="c-svgIcon" role="img">
                                <title>Thumbs Up</title>
                                <use
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#thumbs--thumbs-up"
                                ></use>
                            </svg>
                        </div>
                        <div class="ngInlineBlock">
                            {{ approveText + (itemCount > 1 ? " All" : "") }}
                        </div>
                    </div>
                </div>

                <div
                    class="taskStateChoice taskStateNegative ngCpointer ngNoSelect ngInlineBlock"
                    *ngIf="hasDeny && !showConfirm"
                    (click)="deny()"
                    (keydown.enter)="deny()"
                    tabindex="0"
                >
                    <div class="sprite_deny ngInlineBlock">
                        <svg class="c-svgIcon" role="img">
                            <title>Thumbs Down</title>
                            <use
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#thumbs--thumbs-down"
                            ></use>
                        </svg>
                    </div>
                    <div class="ngInlineBlock">
                        {{ denyText + (itemCount > 1 ? " All" : "") }}
                    </div>
                </div>

                <div *ngIf="showConfirm">
                    <div>Are you sure you want to cancel this event?</div>
                    <button class="aw-button aw-button--primary" (click)="approve()" (keydown.enter)="approve()">
                        Yes
                    </button>
                    <button
                        class="aw-button aw-button--outline"
                        (click)="dismissConfirm()"
                        (keydown.enter)="dismissConfirm()"
                    >
                        No
                    </button>
                </div>
            </div>

            <button
                class="aw-button aw-button--primary"
                (click)="cancel()"
                (keydown.enter)="cancel()"
                tabindex="0"
                *ngIf="hasCancelPerms"
            >
                Cancel
            </button>
        </div>
    `,
    styles: `
        .taskStateChoice {
            overflow-wrap: anywhere;
        }

        .ngTaskActionButtons {
            display: flex;
            column-gap: 1em;
            row-gap: 0.5em;
        }
    `,
})
export class S25TaskActionComponent implements OnInit {
    @Input() taskId: number;
    @Input() taskBlocked: boolean;
    @Input() itemCount: number;
    @Input() taskState: Task.State;
    @Input() taskType: Task.Id;
    @Input() requesterId: number;
    @Input() eventId: number;
    @Input() assignedToId: number;
    @Input() todoType: Task.Todo.Type;
    @Input() todoSubType: Task.SubTypes;
    @Input() objectType: number; // Resource/Location
    @Input() objectId: number; // The id of the requested object

    @Output() stateChange = new EventEmitter<Task.State>();

    showConfirm = false;
    init = false;
    isLoading = false;
    hasApprove = false;
    hasDeny = false;
    hasCancelPerms: boolean = false;
    workflowChained: boolean;
    approveText: string;
    denyText: string;
    hasApproveDenyPerms: boolean = false;
    itemText: string;
    userId: number;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        const [fls, userId, workflowPref, eventOls] = await Promise.all([
            FlsService.getFls(),
            UserprefService.getContactId(),
            TaskTiersService.isWorkflowChained(),
            this.eventId && TaskActionCellFactory.getActionCellsOls([this.eventId]),
        ]);

        this.userId = userId;
        const editAccess = S25Util.propertyGetParentWithChildValue(eventOls, "object_id", this.eventId)?.access_level;
        this.hasCancelPerms =
            editAccess &&
            editAccess !== AccessLevels.None &&
            fls.TASK_CANCEL === AccessLevels.Full &&
            this.taskType === Task.Ids.Assign &&
            this.taskState === Task.States.InProgress &&
            this.requesterId === this.userId;

        this.workflowChained = workflowPref;
        this.update();
    }

    update = () => {
        this.hasApproveDenyPerms = false;
        this.hasApprove = false;
        this.hasDeny = false;
        if (
            this.taskState === Task.States.InProgress &&
            ([Task.Todo.Types.NeedsSpace, Task.Todo.Types.HeadCountAdj].includes(this.todoType) ||
                this.assignedToId === this.userId)
        ) {
            //if task is in-progress AND (admin OR assigned to user)
            this.hasApproveDenyPerms = true;
            this.denyText = "Deny";
            if (this.taskType === Task.Ids.FYI) this.approveText = "Acknowledge";
            else if (this.taskType === Task.Ids.Assign) this.approveText = "Assign";
            else if (this.taskType === Task.Ids.UnAssign) this.approveText = "UnAssign";
            else if (this.taskType === Task.Ids.Todo) {
                this.approveText = "Complete";
                this.denyText = "Ignore";
            } else this.approveText = "Approve";
        } else if (this.taskState === Task.States.Various && this.assignedToId === this.userId) {
            //if invalid-state and assigned to user
            this.itemText = "See Event";
        } else if (this.taskState === Task.States.Declined && this.taskType === Task.Ids.Authorization) {
            // ANG-1418 -- notify tasks can be approved after denial
            this.hasApproveDenyPerms = true;
            this.approveText = "Approve Previously Denied";
        } else {
            //else: no actions for user...
            this.itemText = "None";
        }

        if (this.hasApproveDenyPerms) {
            this.hasDeny = this.hasApproveDenyPerms && this.taskType !== Task.Ids.FYI;

            //ANG-1418 - Notification tasks can be approved after denial
            if (this.taskState === Task.States.Declined) {
                this.hasApprove = Task.Ids.Authorization === this.taskType;
                this.hasDeny = false;
            } else this.hasApprove = true;
        }

        if (this.workflowChained && this.taskBlocked) {
            this.hasDeny = false;
            this.hasApprove = false;
        }
        this.cd.detectChanges();
    };

    approve = () => {
        if (
            !this.showConfirm &&
            this.eventId &&
            (this.todoType || this.taskType === Task.Ids.Todo) &&
            this.todoSubType === Task.SubTypes.cancel
        ) {
            this.showConfirm = true;
            this.cd.detectChanges();
            return;
        }

        this.showConfirm = false;
        this.cd.detectChanges();
        return this.changeTask(Task.States.Assigned);
    };

    dismissConfirm = () => {
        this.showConfirm = false;
        this.cd.detectChanges();
    };

    deny = () => {
        return this.changeTask(Task.States.Denied);
    };

    cancel = () => {
        return this.changeTask(Task.States.Cancelled);
    };

    changeTask = async (newState: Task.State) => {
        const taskData: TaskChange = {
            itemId: this.taskId,
            itemTypeId: this.taskType,
            eventId: this.eventId,
            todoType: this.todoType,
            isTodo: this.taskType === Task.Ids.Todo,
            todoSubType: this.todoSubType,
            itemStateId: this.taskState,
            itemCount: this.itemCount,
            objectType: this.objectType,
            objectId: this.objectId,
        };
        this.isLoading = true;
        this.cd.detectChanges();
        const [results, err] = await S25Util.Maybe(TaskService.changeTask(taskData, newState));
        if (err) {
            this.isLoading = false;
            this.cd.detectChanges();
            return S25Util.showError(err, "Something went wrong when updating the task");
        }
        const result = results[0];
        if (result.success) this.taskState = newState;
        if (result.error === "Deny") {
            this.taskState = this.itemCount === 1 ? Task.States.Denied : newState;
        }
        this.stateChange.emit(this.taskState);

        this.update();

        this.isLoading = false;
        this.cd.detectChanges();
    };
}
