import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
    ViewRef,
    EventEmitter,
    Output,
    ViewChild,
} from "@angular/core";
import { ModalData } from "../modal/modal.service";
import { ModalHeaderI } from "../modal/modal.header.component";
import { Task } from "../../pojo/Task";
import { EventIncludeOption, EventService } from "../../services/event.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { jSith } from "../../util/jquery-replacement";
import { TaskService } from "../../services/task/task.service";
import { ContactService } from "../../services/contact.service";
import { UserprefService } from "../../services/userpref.service";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { S25ItemI } from "../../pojo/S25ItemI";
import { TaskActionCellFactory } from "../../services/task/task.list.service";

export interface ModalCreateEditTaskModel extends ModalData, ModalHeaderI {
    type: "create" | "edit";
    taskType: Task.Id;
    eventId: number;
    taskId: number;
    listModelBean: any;
    taskActionsCell: any;
    taskCount: number;
    objectType: string;
    objectId: string;
    taskBlocked?: boolean;
    showAllApproveMsg: boolean;
    editCompCallBack?(): void;

    callback(taskId: number, value: any): void;
}

@TypeManagerDecorator("s25-ng-create-edit-task-modal")
@Component({
    selector: "s25-ng-create-edit-task-modal",
    template: `
        <div *ngIf="isInit">
            <s25-modal-header [data]="data"></s25-modal-header>
            <div class="modal-body c-padding-bottom--single" *ngIf="isInit">
                <div *ngIf="inProgress" class="c-modal-flex-container c-modal-event-container">
                    <div *ngIf="eventName" class="c-modal-flex-item">
                        <label class="ngAlignLabelHoriz bold">{{ taskLang.text.associated_event }}</label>
                        <div class="ngInlineBlock">{{ eventName }}</div>
                    </div>
                    <div class="c-modal-flex-item c-posRelative">
                        <label for="taskName" class="ngAlignLabelHoriz c-verticalAlign--baseline bold">
                            {{ taskLang.text.todo_name }}
                        </label>
                        <span *ngIf="!isTodo">{{ taskNameBean.value }}</span>
                        <div *ngIf="isTodo" class="limit-width">
                            <s25-ng-editable-text [(val)]="taskNameBean.value" (blurred)="taskNameBean.onChange()">
                            </s25-ng-editable-text>
                        </div>
                    </div>
                    <div *ngIf="isTodo" class="c-modal-flex-item c-margin-top--single">
                        <label class="ngAlignLabelHoriz bold">{{ taskLang.text.assigned_by }}</label>
                        <div class="ngInlineBlock">{{ assignedByName }}</div>
                    </div>
                    <div *ngIf="isCreate" class="c-modal-flex-item type-create">
                        <label for="taskComment" class="ngAlignLabelHoriz c-verticalAlign--baseline bold">{{
                            taskLang.text.todo_comment
                        }}</label>
                        <div class="limit-width">
                            <s25-ng-editable-textarea
                                [val]="taskComment"
                                (valChange)="taskComment = $event"
                            ></s25-ng-editable-textarea>
                        </div>
                    </div>
                    <div *ngIf="isCreate" class="c-margin-top--half c-modal-flex-item">
                        <label class="ngAlignLabelHoriz c-verticalAlign--baseline bold">{{ taskLang.text.date }}</label>
                        <s25-ng-editable-date
                            class="ngInlineBlock ngTaskModalDate"
                            [val]="date"
                            (valChange)="date = $event"
                            [dateFormat]="dateFormat"
                            [popoverOnBody]="true"
                        ></s25-ng-editable-date>
                    </div>
                    <p *ngIf="repeatedElements.length > 1">&nbsp;</p>
                    <div
                        class="ngModalTaskActionContainer"
                        s25-infinite-scroll
                        [onScroll]="this.infiniteScrollAction"
                        [hasMorePages]="this.scrollHasMorePages"
                        [topSelector]="'.modal-body'"
                    >
                        <div
                            *ngFor="let obj of repeatedElements | slice: 0 : actionsShown"
                            class="ngModalTaskActionRow c-modal-flex-container c-padding-bottom--single"
                        >
                            <div
                                *ngIf="data.taskType === 3 || data.taskType === 4"
                                class="c-modal-flex-item c-margin-top--single"
                            >
                                <label class="ngAlignLabelHoriz bold">{{ taskLang.text.event_date }}</label>
                                <span>{{ obj.eventDate | dateFormat: dateFormat }}</span>
                            </div>
                            <div class="c-modal-flex-item c-margin-top--single c-posRelative">
                                <label class="ngAlignLabelHoriz bold">{{ taskLang.text.todo_comment }}</label>
                                <div class="limit-width">
                                    <s25-ng-editable-textarea
                                        [val]="obj.comment.value"
                                        (valChange)="obj.comment.value = $event; editTaskComment($event)"
                                        [readOnly]="isCommentReadyOnly"
                                    ></s25-ng-editable-textarea>
                                </div>
                            </div>
                            <div class="c-modal-flex-item c-margin-top--single c-posRelative">
                                <label class="ngAlignLabelHoriz bold">{{ taskLang.text.date }}</label>
                                <s25-ng-editable-date
                                    class="ngInlineBlock"
                                    [(val)]="obj.dueDate.date"
                                    (valChange)="editDueDate($event)"
                                    [dateFormat]="dateFormat"
                                ></s25-ng-editable-date>
                            </div>
                            <div class="c-modal-flex-item c-margin-top--single">
                                <label class="ngAlignLabelHoriz bold">{{ taskLang.text.actions }}</label>
                                <div *ngIf="obj.taskAction && obj.taskAction.assignedToId">
                                    <s25-ng-task-action
                                        class="ngTaskModalAction"
                                        [assignedToId]="obj.taskAction.assignedToId"
                                        [taskState]="obj.taskAction.itemStateId"
                                        [taskId]="obj.taskAction.itemId"
                                        [taskBlocked]="obj.taskAction.taskBlocked"
                                        [itemCount]="obj.taskAction.itemCount"
                                        [taskType]="obj.taskAction.itemTypeId"
                                        [requesterId]="obj.taskAction.requesterId"
                                        [eventId]="obj.taskAction.eventId"
                                        [todoType]="obj.taskAction.todoType"
                                        [todoSubType]="obj.taskAction.todoSubType"
                                        (stateChange)="stateChange($event)"
                                    ></s25-ng-task-action>
                                </div>
                                <div *ngIf="!obj.taskAction || obj.taskAction.assignedToId === null">None</div>
                            </div>
                            <!--   [modelBean]="obj.taskAction" -->
                            <p>&nbsp;</p>
                        </div>
                    </div>
                    <div class="c-modal-flex-item">
                        <label class="ngAlignLabelHoriz ngAssignedToContacts bold">{{
                            taskLang.text.assigned_to
                        }}</label>
                        <div *ngIf="isTodo" class="ngInlineBlock">
                            <s25-ng-multiselect-search-criteria
                                [type]="'contacts'"
                                [selectedItems]="multiSelectBeanTo.selectedItems"
                                [modelBean]="multiSelectBeanTo"
                                [popoverOnBody]="true"
                                [popoverPlacement]="'right'"
                            ></s25-ng-multiselect-search-criteria>
                        </div>
                        <div *ngIf="!isTodo">
                            <s25-ng-task-contacts-picker
                                [tasks]="taskNode"
                                (contactsAdded)="contactsAdded($event)"
                                [eventId]="data.eventId"
                                [isOnBody]="true"
                            ></s25-ng-task-contacts-picker>
                        </div>
                        <!--notify tasks-->
                        <div *ngIf="data.taskType === 1 || data.taskType === 2" class="task-table--container">
                            <table *ngFor="let status of approvalState" class="ngListTbl">
                                <tr>
                                    <th>{{ status[0] }}</th>
                                </tr>
                                <tr *ngFor="let approver of status[1]">
                                    <td>{{ approver }}</td>
                                </tr>
                            </table>
                        </div>
                        <!--ap tasks-->
                        <div *ngIf="data.taskType === 3 || data.taskType === 4" class="c-assignedTo-flexWrapper">
                            <span *ngFor="let contact of taskNode.approval_contact" class="c-approvalContact">
                                {{ contact.approval_contact_name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="ngCenterAlignText ngInline" *ngIf="!inProgress">
                    <div class="ngBold">{{ taskLang.text.success }}</div>
                    <br />
                    <div>{{ successMsg }}</div>
                    <br />
                    <div>
                        <button class="btn btn-default" *ngIf="isCreate" (click)="createAnotherTodo()">
                            {{ taskLang.text.create_another }}
                        </button>
                    </div>
                </div>
            </div>
            <s25-loading-inline [model]="{}"></s25-loading-inline>
            <div class="modal-footer" *ngIf="isInit && (isCreate || taskActionAll || resultMsg)">
                <div *ngIf="resultMsg">{{ resultMsg }}</div>
                <div *ngIf="taskActionAll && taskActionAll.assignedToId">
                    <s25-ng-task-action
                        class="ngTaskModalAction"
                        [assignedToId]="taskActionAll.assignedToId"
                        [taskState]="taskActionAll.itemStateId"
                        [taskId]="taskActionAll.itemId"
                        [taskBlocked]="taskActionAll.taskBlocked"
                        [itemCount]="taskActionAll.itemCount"
                        [taskType]="taskActionAll.itemTypeId"
                        [requesterId]="taskActionAll.requesterId"
                        [eventId]="taskActionAll.eventId"
                        [todoType]="taskActionAll.todoType"
                        [todoSubType]="taskActionAll.todoSubType"
                        [objectId]="taskActionAll.objectId"
                        [objectType]="taskActionAll.objectType"
                        (stateChange)="stateChange($event)"
                    ></s25-ng-task-action>
                </div>
                <div *ngIf="isCreate" class="aw-button-group">
                    <button class="aw-button aw-button--outline" (click)="close()" [hidden]="!inProgress">
                        Cancel
                    </button>
                    <button class="aw-button aw-button--outline" (click)="close()" [hidden]="inProgress">Close</button>
                    <button class="aw-button aw-button--primary" [hidden]="!inProgress" (click)="createTodo()">
                        {{ goText }}
                    </button>
                </div>
            </div>
        </div>
    `,
    styles: `
        .limit-width {
            max-width: 212px;
        }

        .ngModalTaskActionContainer {
            width: 100%;
        }

        ::ng-deep .s25-multiselect-popup-container {
            max-width: 40vw;
            max-height: 80vh;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
})
export class ModalCreateEditTaskComponent implements OnInit, OnChanges {
    @Input() data: ModalCreateEditTaskModel;
    @Output() onStateChange = new EventEmitter<Task.State>();
    isInit: boolean;
    inProgress = true;
    taskLang: any;
    isCreate: boolean;
    isTodo: boolean;
    goText: string;
    successMsg: string;
    multiSelectBeanTo: any;
    eventIncludes: EventIncludeOption[] = ["workflow"];
    taskNameBean: { value: string; isTextarea: boolean; onChange: () => void };
    timeFormat: string;
    dateFormat: string;
    resultMsg: string;
    assignedByName: string;
    assignedById: number;
    eventName: string | boolean;
    assignedToId: number;
    repeatedElements: any[] = [];
    date: Date;
    taskNode: any;
    taskActionAll: any;
    taskComment: string = "";
    userName: string;
    userId: number;
    approvalState: any[];
    taskData: any;
    actionsShown: number = 10;
    infiniteScrollAction: () => Promise<void>;
    scrollHasMorePages: () => boolean;
    isCommentReadyOnly: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnChanges = (changes: SimpleChanges) => {
        this.isTodo = this.data.taskType === Task.Ids.Todo;
        this.isCreate = this.data.type === "create";
    };

    ngOnInit() {
        this.data.helpLink = "event_todo_create";
        this.isTodo = this.data.taskType === Task.Ids.Todo;
        this.isCreate = this.data.type === "create";

        this.taskLang = S25Util.deepCopy(this.data.lang.div.controls["s25-todo_create"]);
        this.taskLang.text.todo_name = "Task Name:";
        this.taskLang.text.todo_comment = "Comment:";
        this.taskLang.text.event_date = "Event Date:";
        this.taskLang.text.actions = "Actions:";

        // Mock listModelBean unless provided
        this.data.listModelBean ??= {
            editCell: () => {},
            getCell: () => {},
            getRow: () => {},
        };

        this.getTitle(this.data.taskType, this.isCreate);

        if (
            [Task.Ids.Assign, Task.Ids.UnAssign].includes(this.data.taskType) &&
            !this.eventIncludes.includes("reservations")
        )
            this.eventIncludes.push("reservations");

        this.goText = this.isCreate ? this.taskLang.text.create : this.taskLang.text.edit;
        this.successMsg = this.isCreate ? this.taskLang.text.todo_created : this.taskLang.text.todo_updated;

        this.multiSelectBeanTo = {
            singleSelect: true,
            showResult: true,
            buttonText: "Select Contact",
            action: this.editAssignedTo,
            eventId: this.data.eventId,
        };

        this.getData().then((resp) => {
            const { userName, userId, taskData, eventName, dateFormat, timeFormat } = resp;
            this.userName = userName;
            this.userId = userId;
            this.timeFormat = timeFormat;
            this.dateFormat = dateFormat;
            this.taskData = taskData;
            this.taskNameBean = {
                value: S25Util.propertyGet(taskData, "name") || "",
                isTextarea: false,
                onChange: () => this.editTaskName(this.data.taskId, this.taskNameBean.value),
            };

            const inits: any = {
                [Task.Ids.Todo]: () => this.initTodo(eventName, taskData),
                [Task.Ids.UnAssign]: () => this.initAssign(taskData),
                [Task.Ids.Assign]: () => this.initAssign(taskData),
                [Task.Ids.Authorization]: () => this.initFYI(taskData),
                [Task.Ids.FYI]: () => this.initFYI(taskData),
            };
            inits[this.data.taskType]();

            this.initInfiniteScroll();

            this.isInit = true;
            jSith.focusable(this.elementRef.nativeElement, 0, true, true);

            this.changeDetector.detectChanges();
        });
    }

    initTodo = (eventName: string, taskData: any) => {
        if (this.isCreate) this.initCreateTodo(eventName);
        else this.initEditTodo(taskData);
    };

    initAssign = (taskData: any) => {
        const hasApproveDenyPerms = this.initNotTodo(taskData);
        const type = S25Util.propertyGetVal(this.taskNode, "approval_type_id");
        const objectId = S25Util.propertyGetVal(this.taskNode, "object_id");
        const objectType = S25Util.propertyGetVal(this.taskNode, "object_type");

        const reservations: any = {};
        const profiles = S25Util.propertyGet(taskData, "profile") || [];
        for (let profile of profiles) {
            for (let res of profile.reservation) reservations[res.reservation_id] = res.reservation_start_dt;
        }

        const approvals = [];
        for (let approval of taskData.approval) {
            const correctType = approval.approval_type_id === type;
            const correctObject = approval.object_id === objectId && approval.object_type === objectType;
            if (!correctType || !correctObject) continue;

            const user = approval.approval_contact?.find((contact: any) => contact.approval_contact_id === this.userId);
            const approvalState = user?.[0]?.approval_contact_state;
            approvals.push(approval);

            this.repeatedElements.push({
                taskId: approval.approval_id,
                eventDate: S25Util.date.getDate(reservations[approval.approval_profile_id] || taskData.start_date),
                dueDate: {
                    //date, taskId, isTodo, taskCount, eventId, objectType, objectId, assignedToId, assignedById
                    date: S25Util.date.getDate(S25Util.propertyGet(approval, "respond_by") || new Date()),
                    taskId: approval.approval_id,
                    assignedToId: hasApproveDenyPerms ? this.userId : null,
                    assignedById: parseInt(approval.assigned_to_id),
                    eventId: this.data.eventId,
                    callback: this.dueDateCallback,
                },
                comment: {
                    value: S25Util.propertyGetVal(approval, "approval_comments"),
                    taskId: approval.approval_id,
                    assignedToId: hasApproveDenyPerms ? this.userId : null,
                    assignedById: parseInt(approval.assigned_to_id),
                    eventId: this.data.eventId,
                    callback: this.commentCallback,
                },
                taskAction: TaskActionCellFactory.generateActionCell(
                    {
                        itemCount: 1,
                        getRow: this.data.listModelBean.getRow,
                        editCell: this.data.listModelBean.editCell,
                        getCell: this.data.listModelBean.getCell,
                        itemStateId: approvalState || S25Util.propertyGetVal(approval, "approval_state"),
                        itemTypeId: this.data.taskActionsCell.itemTypeId,
                        assignedToId: hasApproveDenyPerms ? this.userId : null,
                        assignedById: parseInt(approval.assigned_to_id),
                        requesterId: this.data.taskActionsCell.requesterId,
                        eventId: this.data.eventId,
                        itemId: approval.approval_id,
                        itemName: this.taskNameBean.value,
                        callback: this.taskActionCallback,
                    },
                    this.data.listModelBean,
                ),
            });
        }

        // Approve/deny all
        if (!approvals.length) return;
        this.taskActionAll = TaskActionCellFactory.generateActionCell(
            {
                itemCount: approvals.length,
                objectType,
                objectId,
                itemId: S25Util.propertyGetVal(this.taskNode, "approval_id"),
                getRow: this.data.listModelBean.getRow,
                editCell: this.data.listModelBean.editCell,
                getCell: this.data.listModelBean.getCell,
                itemStateId: S25Util.propertyGetVal(this.taskNode, "approval_state"),
                itemTypeId: this.data.taskActionsCell.itemTypeId,
                assignedToId: hasApproveDenyPerms ? this.userId : null,
                assignedById: parseInt(this.taskNode.assigned_to_id),
                requesterId: this.data.taskActionsCell.requesterId,
                eventId: this.data.eventId,
                itemName: this.taskNameBean.value,
                callback: this.getTaskActionAllCallback(approvals),
            },
            this.data.listModelBean,
        );
    };

    initFYI = (taskData: any) => {
        if (!this.taskNode)
            this.taskNode = S25Util.propertyGetParentWithChildValue(taskData, "approval_id", this.data.taskId);
        const hasApproveDenyPerms = this.initNotTodo(taskData);
        this.repeatedElements.push({
            taskId: this.data.taskId,
            dueDate: {
                //date, taskId, isTodo, taskCount, eventId, objectType, objectId, assignedToId, assignedById
                date: S25Util.date.getDate(S25Util.propertyGet(this.taskNode, "respond_by") || new Date()),
                taskId: this.data.taskId,
                assignedToId: hasApproveDenyPerms ? this.userId : null,
                eventId: this.data.eventId,
                callback: this.dueDateCallback,
            },
            comment: {
                value: S25Util.propertyGetVal(this.taskNode, "approval_comments"),
                taskId: this.data.taskId,
                assignedToId: hasApproveDenyPerms ? this.userId : null,
                eventId: this.data.eventId,
                callback: this.commentCallback,
            },
            taskAction: TaskActionCellFactory.generateActionCell(
                {
                    itemCount: 1,
                    getRow: this.data.listModelBean.getRow,
                    editCell: this.data.listModelBean.editCell,
                    getCell: this.data.listModelBean.getCell,
                    itemStateId: this.data.taskActionsCell.itemStateId,
                    itemTypeId: this.data.taskActionsCell.itemTypeId,
                    assignedToId: hasApproveDenyPerms ? this.userId : null,
                    eventId: this.data.eventId,
                    itemId: this.data.taskId,
                    itemName: this.taskNameBean.value,
                    callback: this.taskActionCallback,
                },
                this.data.listModelBean,
            ),
        });
        this.getApprovalStatuses();
        this.changeDetector.detectChanges();
    };

    initEditTodo = (taskData: any) => {
        this.assignedById = parseInt(S25Util.propertyGet(taskData, "assigned_by_id"));
        this.assignedByName = S25Util.propertyGet(taskData, "assigned_by_name") || "";
        this.eventName = S25Util.propertyGet(taskData, "object_name") || false;
        this.data.eventId = parseInt(S25Util.propertyGet(taskData, "object_id"));
        this.assignedToId = parseInt(S25Util.propertyGet(taskData, "assigned_to_id"));
        this.multiSelectBeanTo.selectedItems = [];
        if (this.assignedToId) {
            const itemName = S25Util.propertyGet(taskData, "assigned_to_name") || "";
            this.multiSelectBeanTo.selectedItems = [{ itemId: this.userId, itemName, checked: true }];
            this.assignedToId === this.userId || this.assignedById === this.userId
                ? (this.isCommentReadyOnly = false)
                : (this.isCommentReadyOnly = true); //ANG-4596
        }

        this.repeatedElements.push({
            taskId: this.data.taskId,
            dueDate: {
                date: S25Util.date.getDate(S25Util.propertyGet(taskData, "due_date") || new Date()),
                taskId: this.data.taskId,
                isTodo: true,
                assignedToId: this.assignedToId,
                assignedById: this.assignedById,
                callback: this.dueDateCallback,
            },
            comment: {
                value: S25Util.propertyGetVal(taskData, "comment"),
                isTodo: true,
                taskId: this.data.taskId,
                assignedToId: this.assignedToId,
                callback: this.commentCallback,
            },
            taskAction: TaskActionCellFactory.generateActionCell(
                {
                    itemCount: 1,
                    getRow: this.data.listModelBean.getRow,
                    editCell: this.data.listModelBean.editCell,
                    getCell: this.data.listModelBean.getCell,
                    itemStateId: this.data.taskActionsCell.itemStateId,
                    itemTypeId: this.data.taskActionsCell.itemTypeId,
                    todoSubType: S25Util.propertyGet(taskData, "todo_subtype"),
                    isTodo: true,
                    todoType: this.data.taskActionsCell.todoType,
                    assignedToId: this.assignedToId,
                    eventId: this.data.eventId,
                    itemId: this.data.taskId,
                    itemName: this.taskNameBean.value,
                    callback: this.taskActionCallback,
                },
                this.data.listModelBean,
            ),
        });
        this.changeDetector.detectChanges();
    };

    initCreateTodo = (eventName: string) => {
        this.multiSelectBeanTo.selectedItems = [{ itemId: this.userId, itemName: this.userName, checked: true }]; // Default assigned-to to current user
        this.assignedById = this.userId;
        this.assignedByName = this.userName;
        this.eventName = eventName || false;
        this.date = new Date();
        this.changeDetector.detectChanges();
    };

    initNotTodo = (taskData: any) => {
        this.taskNode = S25Util.propertyGetParentWithChildValue(taskData, "approval_id", this.data.taskId);
        if (this.taskNode === null) this.taskNode = this.taskData?.approval[0]; // adding this for task calendar view
        const hasApproveDenyPerms = TaskService.hasApproveDenyPerms(this.taskNode, this.userId);
        this.taskNameBean.value = S25Util.propertyGet(this.taskNode, "approval_name") || "";
        this.eventName = S25Util.propertyGet(taskData, "event_name") || false;
        this.isCommentReadyOnly = !hasApproveDenyPerms; //ANG-4596
        return hasApproveDenyPerms;
    };

    initInfiniteScroll = () => {
        let working = false;
        const pageSize = 10;
        let pagesShown = 1;

        this.scrollHasMorePages = () => pageSize * pagesShown < this.repeatedElements.length;
        this.infiniteScrollAction = async (callback?: () => void) => {
            if (!working && this.scrollHasMorePages()) {
                working = true;
                await S25Util.delay(0);
                this.actionsShown = pageSize * ++pagesShown;
            }
            callback?.();
            working = false;
        };
    };

    getData = () => {
        const { eventId, taskId } = this.data;
        const dataPromise = this.isTodo
            ? taskId && TaskService.getTodo(taskId)
            : eventId && EventService.getEventInclude(eventId, this.eventIncludes, null, true);
        const eventNamePromise =
            this.isTodo && eventId && !this.isCreate && EventService.getEventName(this.data.eventId);

        return Promise.all([
            ContactService.getCurrentName(),
            ContactService.getCurrentId(),
            dataPromise,
            eventNamePromise,
            UserprefService.getS25Dateformat(),
            UserprefService.getS25Timeformat(),
        ]).then(([userName, userId, taskData, eventName, dateFormat, timeFormat]) => {
            return { userName, userId, taskData, eventName, dateFormat, timeFormat };
        });
    };

    getTitle = (taskType: Task.Id, isCreate: boolean) => {
        if ([Task.Ids.FYI, Task.Ids.Authorization].includes(taskType)) this.data.title = "Edit Notification Task";
        else if ([Task.Ids.Assign, Task.Ids.UnAssign].includes(taskType)) this.data.title = "Edit Assignment Request";
        else if (isCreate) this.data.title = this.taskLang.text.create_todo;
        else this.data.title = this.taskLang.text.edit_todo;
    };

    /**
     * Finds all the contacts associated with the current task
     * adds them to this.approvalState with their task status authorized, in progress etc.
     */
    getApprovalStatuses = () => {
        const messages: { [key: string]: any[] } = {};

        for (let approval of this.taskData.approval) {
            if (approval.object_type === this.taskNode.object_type && approval.object_id === this.taskNode.object_id) {
                for (let contact of approval.approval_contact) {
                    const status = `${contact.notification_type_name} ${contact.approval_contact_state_name
                        .split("/")
                        .join("/ ")}`;
                    messages[status] ??= [];
                    messages[status].push(contact.approval_contact_name);
                    if (this.taskData.notify_type_id == 2) {
                        if (contact.notification_type_id == 2 && !this.data.showAllApproveMsg)
                            this.data.showAllApproveMsg = true;
                        this.data.showAllApproveMsg = !!this.data.showAllApproveMsg;
                    } else this.data.showAllApproveMsg = false;
                }
            }
        }
        this.approvalState = Object.entries(messages)
            .map(([type, arr]) => [type, arr.sort()])
            .sort();
    };

    editTaskName = async (id: number, name: string) => {
        if (name === "") return;
        if (!this.isTodo || this.isCreate) return;
        return TaskService.putTodoName(id, name.trim()).then(() => {
            this.editCell({ itemName: name }, "todo", id, "task_item");
            // Must also edit origItemName bc it is used to set # requests in names and ends up overwriting itemName
            this.editCell({ origItemName: name }, "todo", id, "task_item");
            this.successF();
        }, this.errorF);
    };

    successF = async () => {
        this.resultMsg = "";
        await S25Util.delay(250);
        this.resultMsg = "Success saving data at " + S25Datefilter.transform(new Date(), this.timeFormat);
        this.changeDetector.detectChanges();
        this.data.editCompCallBack?.();
    };

    errorF = async () => {
        this.resultMsg = "";
        await S25Util.delay(250);
        this.resultMsg = "Error saving data at " + S25Datefilter.transform(new Date(), this.timeFormat);
        this.changeDetector.detectChanges();
    };

    editCell = (data: any, type: string, id: number, prefName: string, extend = true, refresh = false) => {
        this.data.listModelBean.editCell(data, this.data.listModelBean.getRow(type + id), prefName, extend, refresh);
    };

    dueDateCallback = (taskId: number, dueDate: Date) => {
        this.editCell({ date: dueDate }, this.isTodo ? "todo" : "task", taskId, "respond_by");
    };

    commentCallback = (taskId: number, comment: string) => {
        this.editCell({ value: comment }, this.isTodo ? "todo" : "task", taskId, "comments");
    };

    taskActionCallback = (callback: () => void) => {
        S25Util.refreshNgFor(this, "repeatedElements");
        this.successF();
        this.data.editCompCallBack?.(); //worflow, need to call back and refresh the data
        if (S25Util.isFunction(callback)) callback();
    };

    stateChange(e: number) {
        this.successF();
    }

    getTaskActionAllCallback = (approvals: any[]) => {
        return (itemStateId: any, itemName: string, contacts: any[]) => {
            // Set in taskAction callback, eg: scope.modelBean.callback(newState, ...);
            // update all underlying tasks with overall new state, name, and contacts
            for (let approval of approvals) {
                const row = this.data.listModelBean.getRow("task" + approval.approval_id);
                this.data.listModelBean.editCell({ itemStateId }, row, "task_item", true, false);
                this.data.listModelBean.editCell({ itemStateId }, row, "actions", true, false);
                const assignedToCell = this.data.listModelBean.getCell(row, "assigned_to"); // Get assigned to cell
                const assignedToTask = assignedToCell && assignedToCell.task; // Get task listed on that cell
                // Update contacts array in assigned_to cell to the new array values returned from the service call
                if (contacts && assignedToTask?.approval_contact) {
                    assignedToTask.approval_contact = contacts;
                    this.data.listModelBean.editCell({ task: assignedToTask }, row, "assigned_to", true, false);
                }
                // Status is updated to overall state id so users can still see the overall state
                this.data.listModelBean.editCell({ itemStateId, itemName }, row, "status", true, false);
            }
            this.taskActionCallback(this.close);
            this.data.editCompCallBack?.(); //worflow, need to call back and refresh the dataset
        };
    };

    close = () => {
        this.data.closeModal();
    };

    createTodo = () => {
        if (!this.validate()) return;
        S25LoadingApi.init(this.elementRef.nativeElement);
        const taskItem = {
            eventId: this.data.eventId,
            taskName: this.taskNameBean.value,
            taskComment: this.taskComment,
            dueDate: this.date,
            assignedById: this.assignedById,
            assignedToId: this.multiSelectBeanTo.selectedItems[0].itemId,
        };
        if (this.isCreate && !this.data.eventId) {
            return TaskService.createPlainTodo(taskItem)
                .then((isSuccess) => {
                    if (!isSuccess) return;
                    this.inProgress = false;
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                    this.changeDetector.detectChanges();
                })
                .catch(function (err) {
                    S25Util.showError(err);
                });
        } else if (this.isCreate && this.data.eventId) {
            TaskService.createEventTodo(taskItem).then((isSuccess) => {
                if (isSuccess) {
                    this.inProgress = false;
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                    this.changeDetector.detectChanges();
                }
            });
        }
    };

    validate = () => {
        let isValid = true;
        if (!this.taskNameBean.value) {
            alert(this.taskLang.text.missing_name);
            isValid = false;
        } else if (this.taskNameBean.value.length > 40) {
            alert("Task name must be 40 characters or less");
            isValid = false;
        } else if (this.multiSelectBeanTo.selectedItems.length === 0) {
            alert(this.taskLang.text.missing_contact);
            isValid = false;
        } else if (!S25Util.date.isValid(this.date)) {
            alert(this.taskLang.text.missing_date);
            isValid = false;
        }
        return isValid;
    };

    createAnotherTodo = async () => {
        this.taskNameBean.value = "";
        this.taskComment = "";
        this.date = S25Util.date.getDate(new Date());
        this.multiSelectBeanTo.selectedItems = [{ itemId: this.userId, itemName: this.userName, checked: true }]; // Default assigned-to to current user
        this.inProgress = await S25Util.delay(0, true);
        this.changeDetector.detectChanges();
    };

    editAssignedTo = () => {
        const selected = this.multiSelectBeanTo.selectedItems?.[0];
        if (!this.isTodo || this.isCreate || !selected || this.assignedToId === parseInt(selected.itemId)) return;
        this.assignedToId = selected.itemId;
        const newName = TaskService.formSingleContactString(selected.itemName, String(this.assignedToId), this.userId);
        return TaskService.putTodoAssignment(this.data.taskId, selected.itemId).then(() => {
            this.editCell(newName, "todo", this.data.taskId, "assigned_to", false);
            this.successF();
        }, this.errorF);
    };

    editTaskComment = async (value: string) => {
        let putPromise: Promise<any>;
        if (this.isTodo) putPromise = TaskService.putTodoComment(this.data.taskId, value);
        else putPromise = TaskService.putEventTaskComment(this.data.eventId, this.data.taskId, value);
        const [_, err] = await S25Util.Maybe(putPromise);
        if (err) return this.errorF();
        this.successF();
        this.data.callback?.(this.data.taskId, value);
    };

    editDueDate = async (date: Date) => {
        const finish = () => this.data.callback?.(this.data.taskId, date);
        let putPromise: Promise<any>;
        if (this.isTodo) putPromise = TaskService.putTodoDueDate(this.data.taskId, date);
        else if (this.data.taskCount > 1) {
            // Set all tasks with matching approvalType, objectType, and objectId, to the new date
            const eventData = await EventService.getEventInclude(this.data.eventId, ["workflow"]);
            const approvals = S25Util.propertyGet(eventData, "approval");
            if (!approvals) return finish();
            const approvalType = parseInt(
                S25Util.propertyGetVal(
                    S25Util.propertyGetParentWithChildValue(approvals, "approval_id", this.data.taskId),
                    "approval_type_id",
                ),
            );
            if (!approvalType) return finish();
            approvals.map((item: any) => {
                if (
                    item &&
                    parseInt(item.approval_type_id) === approvalType &&
                    parseInt(item.object_type) === parseInt(this.data.objectType) &&
                    parseInt(item.object_id) === parseInt(this.data.objectId)
                ) {
                    item.status = "mod";
                    item.respond_by = S25Util.date.toS25ISODateStrEndOfDay(date);
                }
            });
            eventData.status = "mod";
            putPromise = EventService.putEvent(this.data.eventId, { events: { event: eventData } });
        } else {
            putPromise = TaskService.putEventTaskDueDate(this.data.eventId, this.data.taskId, date);
        }
        const [_, err] = await S25Util.Maybe(putPromise);
        if (err) return this.errorF();
        this.successF();
        finish();
    };

    /*
    To be run once new contacts are added to the task.
    NP Auth - adds new contacts to "authorization in progress list"
    NP FYI - adds new contacts to "FYI in progress list"
    AP - adds to "Assigned To" list
     */
    contactsAdded = (contacts: { type: string; items: S25ItemI[] }) => {
        if (!contacts) return;
        contacts.items = S25Util.array.forceArray(contacts.items);
        if ([Task.Ids.FYI, Task.Ids.Authorization].includes(this.data.taskType)) {
            //Add to the new notify to the proper group it will always be in progress
            contacts.items.forEach((cont) => {
                if (cont.notifyType === 1) {
                    //FYI In Progress
                    let fyiIndex = this.approvalState.findIndex((group) => {
                        return group[0] === "FYI In Progress";
                    });

                    if (fyiIndex === -1) {
                        this.approvalState.push(["FYI In Progress"]);
                        fyiIndex = this.approvalState.length;
                    }
                    this.approvalState[fyiIndex][1].push(cont.itemName);
                } else if (cont.notifyType === 2) {
                    //Authorization In Progress
                    let approvalIndex = this.approvalState.findIndex((group) => {
                        return group[0] === "Authorization In Progress";
                    });

                    if (approvalIndex === -1) {
                        this.approvalState.unshift(["Authorization In Progress"]);
                        approvalIndex = 0;
                    }
                    this.approvalState[approvalIndex][1].push(cont.itemName);
                }
            });
        } else if ([Task.Ids.Assign, Task.Ids.UnAssign].includes(this.data.taskType)) {
            contacts.items.forEach((cont) => {
                this.taskNode.approval_contact.push({
                    approval_contact_name: cont.itemName,
                    approval_contact_id: cont.itemId,
                    approval_contact_state: 1,
                });
            });
        }
        this.changeDetector.detectChanges();
    };
}
